/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
'use client';
import { init, track, setUserId } from '@amplitude/analytics-browser';
import { useEffect, createContext, useContext } from 'react';

import { config } from '@teamchat-shared/lib/config';

export const AmplitudeContext = createContext({
  trackAmplitudeEvent: (
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties: Record<string, any>
  ) => {},
  setUser: (userId: string) => {},
});

type Props = {
  children: React.ReactNode;
};

const AmplitudeProvider = ({ children }: Props) => {
  useEffect(() => {
    init(config.amplitudeApiKey, undefined, {
      defaultTracking: false,
    });
  }, []);

  const trackAmplitudeEvent = (
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties: Record<string, any>
  ) => {
    track(eventName, eventProperties);
  };

  const setUser = (userId: string) => {
    setUserId(userId);
  };

  const value = { trackAmplitudeEvent, setUser };

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext);
  if (context === undefined)
    throw new Error(
      'useAmplitudeContext must be used within a AmplitudeProvider'
    );
  return context;
};

export default AmplitudeProvider;
