import { env } from 'next-runtime-env';

export const config = {
  env: env('NEXT_PUBLIC_ENV') ?? '',
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  clientId: env('NEXT_PUBLIC_CLIENT_ID') ?? '',
  tcApiURL: env('NEXT_PUBLIC_TEAMCHAT_API_URL') ?? '',
  appURL: env('NEXT_PUBLIC_APP_URL') ?? '',
  appProtocol: env('NEXT_PUBLIC_APP_PROTOCOL') ?? 'https://',
  accountsURL: env('NEXT_PUBLIC_ACCOUNTS_URL') ?? '',
  billingURL: env('NEXT_PUBLIC_BILLING_URL') ?? '',
  wsAgentChatUrl: env('NEXT_PUBLIC_WS_AGENT_CHAT_URL') ?? '',
  wsAgentChatUrlFra: env('NEXT_PUBLIC_WS_AGENT_CHAT_URL_FRA') ?? '',
  wsChatUrl: env('NEXT_PUBLIC_WS_CHAT_URL') ?? '',
  textApiUrl: env('NEXT_PUBLIC_TEXT_API_URL') ?? '',
  licenseId: env('NEXT_PUBLIC_LICENSE_ID') ?? '',
  lcCDNURL: env('NEXT_PUBLIC_LC_CDN_URL') ?? '',
  lcClientId: env('NEXT_PUBLIC_LIVECHAT_CLIENT_ID') ?? '',
  hotjarId: env('NEXT_PUBLIC_HOTJAR_ID') ?? '',
  piwikId: env('NEXT_PUBLIC_PIWIK_ID') ?? '',
  lcAppUrl: env('NEXT_PUBLIC_LC_APP_URL') ?? '',
  amplitudeApiKey: env('NEXT_PUBLIC_AMPLITUDE_API_KEY') ?? '',
} as const;
