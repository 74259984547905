import React, { useReducer, createContext, useContext, Dispatch } from 'react';

interface Loading {
  botLoading: boolean;
  botLoaded: boolean;
  userLoading: boolean;
  userLoaded: boolean;
}

type LoadingAction =
  | { type: 'USER_LOADING'; payload: boolean }
  | { type: 'USER_LOADED'; payload: boolean }
  | { type: 'BOT_LOADING'; payload: boolean }
  | { type: 'BOT_LOADED'; payload: boolean };

const initialState: Loading = {
  botLoading: false,
  botLoaded: false,
  userLoading: false,
  userLoaded: false,
};

const LoadingContext = createContext<
  | {
      state: Loading;
      dispatch: Dispatch<LoadingAction>;
    }
  | undefined
>(undefined);

const loadingReducer = (state: Loading, action: LoadingAction): Loading => {
  switch (action.type) {
    case 'BOT_LOADING':
      return { ...state, botLoading: action.payload };
    case 'BOT_LOADED':
      return { ...state, botLoaded: action.payload };
    case 'USER_LOADING':
      return { ...state, userLoading: action.payload };
    case 'USER_LOADED':
      return { ...state, userLoaded: action.payload };

    default:
      return state;
  }
};

type Props = {
  children: React.ReactNode;
};

export const LoadingProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(loadingReducer, initialState);

  return (
    <LoadingContext.Provider value={{ state, dispatch }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = (): {
  state: Loading;
  dispatch: Dispatch<LoadingAction>;
} => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
