import styled from '@emotion/styled';
import {
  layout,
  flexbox,
  system,
  grid,
  space,
  position,
  color,
  lineHeight,
  boxShadow,
  LayoutProps,
  FlexboxProps,
  SpaceProps,
  PositionProps,
  ColorProps,
  border,
  BorderProps,
  GridProps,
  LineHeightProps,
  BoxShadowProps,
} from 'styled-system';

import { GapProps } from '../types/gap';

const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
});

interface Props
  extends LayoutProps,
    FlexboxProps,
    GridProps,
    SpaceProps,
    GapProps,
    ColorProps,
    PositionProps,
    BorderProps,
    LineHeightProps,
    BoxShadowProps {
  as?: React.ElementType;
}

const Box = styled.div<Props>`
  ${layout}
  ${flexbox}
  ${grid}
  ${gap}
  ${space}
  ${position}
  ${color}
  ${border}
  ${lineHeight}
  ${boxShadow}
`;

export default Box;
