import AccountsSDK from '@livechat/accounts-sdk';
import { HelloAccountData } from '@livechat/hello-utils';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useLoading } from '@teamchat-shared/contexts/loading';
import { useLastLocation } from '@teamchat-shared/hooks/useLastLocation';
import api from '@teamchat-shared/lib/api';
import { getToken, removeToken } from '@teamchat-shared/lib/auth';
import { getErrorCode, getStatusCode } from '@teamchat-shared/lib/axios';
import { config } from '@teamchat-shared/lib/config';
import { storage } from '@teamchat-shared/lib/storage';

import { useAmplitudeContext } from './amplitude';

export const accountsSDK = new AccountsSDK({
  client_id: config.clientId,
  server_url: config.accountsURL,
});

type OAuth = {
  account: HelloAccountData;
  token: string;
};

export const OAuthContext = React.createContext<OAuth>(undefined as any);

type Props = {
  children: React.ReactNode;
};

function OAuth({ children }: Props) {
  const router = useRouter();
  const [account, setAccount] = useState<HelloAccountData | null>(null);
  const [token, setToken] = useState<string>('');
  const isLoading = React.useRef(false);

  const { dispatch } = useLoading();
  const { setUser } = useAmplitudeContext();

  useLastLocation();

  useEffectOnce(() => {
    async function handleAuth() {
      try {
        dispatch({ type: 'USER_LOADING', payload: true });

        let token = getToken();

        if (!token) {
          const { access_token } = await accountsSDK.redirect().authorizeData();
          token = access_token;
        }

        isLoading.current = true;

        if (token) {
          api.initialize(token);
          setToken(token);
        }

        try {
          const myAccount = await api.teamChat.getMyAccount();

          setAccount(myAccount);
          storage.setItem('token', token);

          setUser(myAccount.gaccProfile.id);

          if (window.hj) {
            try {
              window.hj('identify', myAccount.gaccProfile.id, {
                email: myAccount.gaccProfile.email,
              });
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
            }
          }
        } catch (error) {
          const statusCode = getStatusCode(error);
          const errorCode = getErrorCode(error);

          if (statusCode === 401) {
            removeToken();

            switch (errorCode) {
              // Subscription expired, for now redirect to subscription page
              case 'LS001': {
                const accountsURL = new URL(`${config.accountsURL}`);

                accountsURL.searchParams.set('client_id', config.clientId);
                accountsURL.searchParams.set(
                  'redirect_uri',
                  `${config.appURL}/app`
                );
                accountsURL.searchParams.set('response_type', 'token');

                window.location.href = accountsURL.toString();
                return;
              }

              default:
                accountsSDK.redirect().authorize();
            }
          } else if (statusCode === 400) {
            switch (errorCode) {
              // Subscription expired, for now redirect to subscription page
              case 'BG001':
                window.location.href = `${config.lcAppUrl}/subscription`;
                return;

              default:
                router.push('/oops');
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('error', error);
            router.push('/oops');
          }
        }
      } catch (error) {
        removeToken();
        accountsSDK.redirect().authorize();
      } finally {
        dispatch({ type: 'USER_LOADED', payload: true });
        dispatch({ type: 'USER_LOADING', payload: false });
        isLoading.current = false;
      }

      // Remove the hash with access token from the URL
      const url = new URL(window.location.href);
      url.hash = '';
      window.history.replaceState({}, document.title, url.toString());
    }

    if (!account) {
      handleAuth();
    }
  });

  // We are casting the account to be not null because we are checking if the account is
  // loaded in the AppPage component
  const value = {
    account: account as HelloAccountData,
    token,
  };

  return (
    <OAuthContext.Provider value={value}>{children}</OAuthContext.Provider>
  );
}

export default OAuth;
