/* eslint-disable no-console */
import axios from 'axios';

import { logout } from '@teamchat-shared/lib/auth';
import { getStatusCode } from '@teamchat-shared/lib/axios';

const instance = axios.create({
  baseURL: '/',
});

class Local {
  constructor(token?: string) {
    if (token) {
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;

      instance.interceptors.response.use(
        (response) => response,
        (error) => {
          const statusCode = getStatusCode(error);

          switch (statusCode) {
            case 401: {
              logout();
              break;
            }

            default:
              throw error;
          }
        }
      );
    }
  }

  async sendMessage(channel: string, text: string) {
    try {
      const { data } = await instance.post(`/api/team-chat/send-message`, {
        channel,
        text,
      });

      return data;
    } catch (error) {
      console.log('error', error);
    }
  }
}

export default Local;
