import Accounts from '@teamchat-shared/api/accounts';
import Billing from '@teamchat-shared/api/billing';
import TeamChat from '@teamchat-shared/api/teamChat';
import Local from '@teamchat-shared/api/local';
import Text from '@teamchat-shared/api/text';

export enum Clients {
  TeamChat = 'teamChat',
  Local = 'local',
  Text = 'text',
  Accounts = 'accounts',
  Billing = 'billing',
}

interface IClients {
  teamChat: TeamChat | null;
  local: Local | null;
  text: Text | null;
  accounts: Accounts | null;
  billing: Billing | null;
}

let clients: IClients = {
  teamChat: null,
  local: null,
  text: null,
  accounts: null,
  billing: null,
};

const api = {
  initialize(token: string, customClients?: Partial<IClients>) {
    clients = {
      teamChat: customClients?.teamChat || new TeamChat(token),
      local: customClients?.local || new Local(token),
      text: customClients?.text || new Text(token),
      accounts: customClients?.accounts || new Accounts(token),
      billing: customClients?.billing || new Billing(token),
    };
  },

  get teamChat(): TeamChat {
    return clients.teamChat as TeamChat;
  },

  get local(): Local {
    return clients.local as Local;
  },

  get text(): Text {
    return clients.text as Text;
  },

  get accounts(): Accounts {
    return clients.accounts as Accounts;
  },

  get billing(): Billing {
    return clients.billing as Billing;
  },
};

export default api;
