import styled from '@emotion/styled';
import { Text as DSText } from '@livechat/design-system-react-components';
import {
  color,
  ColorProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  textAlign,
  TextAlignProps,
  letterSpacing,
  LetterSpacingProps,
} from 'styled-system';

interface Props
  extends ColorProps,
    FontFamilyProps,
    FontSizeProps,
    FontWeightProps,
    LineHeightProps,
    TextAlignProps,
    LetterSpacingProps {
  as?: React.ElementType;
}

const Text = styled(DSText)<Props>`
  ${color}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${textAlign}
  ${letterSpacing}
`;

export default Text;
