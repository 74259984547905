import axios from 'axios';

import { config } from '@teamchat-shared/lib/config';

const instance = axios.create({
  baseURL: config.billingURL,
});

class Billing {
  constructor(token?: string) {
    if (token) {
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  async getSubscriptions() {
    const { data } = await instance.get(`/v3/subscriptions`);

    return data;
  }
}

export default Billing;
