/* eslint-disable no-console */
import { useRouter, usePathname, useParams } from 'next/navigation';
import { useEffect } from 'react';

import { localStorage } from '@teamchat-shared/lib/storage';

export function useLastLocation() {
  const router = useRouter();
  const params = useParams();
  const path = usePathname();

  useEffect(() => {
    if (path !== '/app' && params?.channelId) {
      // Ensure we do not store '/app' as last location
      localStorage.setItem('lastLocation', path ? `${path}` : '');
    }
  }, [params, path]);

  useEffect(() => {
    const lastLocation = localStorage.getItem('lastLocation');
    if (lastLocation && path === '/app') {
      console.log('Redirecting to last location:', lastLocation);
      router.push(lastLocation);
    } else if (!params?.channelId) {
      console.log('Redirecting to general');
      router.push('/app/channel/general');
    } else if (!lastLocation && path === '/app') {
      console.log('Redirecting to general');
      router.push('/app/channel/general');
    }
  }, [path, router, params]);
}
