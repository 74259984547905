import api from './api';
import { config } from './config';
import { storage } from './storage';

export const getToken = () => storage.getItem('token') as string;

export const removeToken = () => storage.removeItem('token');

export const logout = async () => {
  await api.accounts.deleteSessions();
  removeToken();
  window.location.replace(`${config.accountsURL}/logout`);
};

export function getTokenFromBearer(bearerToken: string): string {
  if (!bearerToken) {
    return '';
  }

  const parts = bearerToken.split(' ');
  if (parts.length === 2 && parts[0] === 'Bearer') {
    return parts[1];
  }

  return '';
}
