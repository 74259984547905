import {
  LivechatAgentData,
  LivechatCannedResponseData,
  LivechatChatData,
  LivechatChatEventData,
  LivechatChatMessageEventPostbackData,
  LivechatChatSystemMessageEventData,
  LivechatChatThreadData,
  LivechatEventTypeEnum,
  LivechatEventVisibilityTypeEnum,
  LivechatRoutingStatusEnum,
  LivechatUserTypeEnum,
  TextChatChannelMessageData,
  TextChatChannelTypeEnum,
} from '@livechat/hello-utils';
import axios, { AxiosRequestConfig } from 'axios';

import { config } from '@teamchat-shared/lib/config';

const instance = axios.create({
  baseURL: config.tcApiURL,
});

class TeamChat {
  constructor(token?: string) {
    if (token) {
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  async authWithCode(code: string, redirectUri: string) {
    const { data: responseData } = await instance.post(`/api/auth`, {
      code,
      redirectUri,
    });

    return responseData?.data;
  }

  async getMyAccount() {
    const { data: responseData } = await instance.get(`/api/account`);
    return responseData?.data;
  }

  async onboarding() {
    const { data: responseData } = await instance.get(`/api/onboarding`, {});

    return responseData?.data;
  }

  async completeOnboarding() {
    const { data: responseData } = await instance.post(`/api/onboarding`, {});

    return responseData?.data;
  }

  async checkImages(urls: string[]) {
    const { data: responseData } = await instance.post(
      `/api/chat/normalize/image`,
      {
        urls,
      }
    );
    return responseData?.data;
  }

  async summarizeChat(chatId: string) {
    const { data: responseData } = await instance.post(
      `/api/chat/ai/summarize`,
      {
        chatId,
      }
    );
    return responseData?.data;
  }

  async askOne(chatId: string) {
    const { data: responseData } = await instance.post(`/api/chat/bot/ask`, {
      chatId,
    });
    return responseData?.data;
  }

  async sendInvite() {
    const { data: responseData } = await instance.post(`/api/send-invite`, {});
    return responseData?.data;
  }

  async sendEvent(
    chatId: string,
    value: string | number,
    authorId?: string,
    visibility = 'all',
    postback?: LivechatChatMessageEventPostbackData,
    type: LivechatEventTypeEnum = LivechatEventTypeEnum.Message
  ) {
    const { data: responseData } = await instance.post(`/api/chat/event/send`, {
      chatId,
      type,
      value,
      authorId,
      visibility,
      postback,
    });
    return responseData?.data;
  }

  async getCannedResponses(): Promise<LivechatCannedResponseData[]> {
    const { data: responseData } = await instance.get(
      `/api/chat/canned-response`
    );
    return responseData?.data;
  }

  async addUserToChat(
    chatId: string,
    userId: string,
    userType: LivechatUserTypeEnum,
    visibility: LivechatEventVisibilityTypeEnum
  ) {
    const { data: responseData } = await instance.post(
      `/api/chat/action/add-user-to-chat`,
      {
        chatId,
        userId,
        userType,
        visibility,
      }
    );
    return responseData?.data;
  }

  async resumeChat(chatId: string) {
    const { data: responseData } = await instance.post(
      `/api/chat/action/resume`,
      {
        chatId,
      }
    );
    return responseData?.data;
  }

  async getAgents(): Promise<
    {
      agent: LivechatAgentData;
      lastMessage: TextChatChannelMessageData;
      status: LivechatRoutingStatusEnum;
    }[]
  > {
    const { data: responseData } = await instance.post(`/api/agent/get-all`);

    return responseData?.data;
  }

  async getAgent(email: string): Promise<{
    agent: LivechatAgentData;
    lastMessage: TextChatChannelMessageData;
    status: LivechatRoutingStatusEnum;
  }> {
    const response = await instance.post(`/api/agent/get-one-by-email`, {
      email,
    });

    const responseData = response?.data || {};

    return responseData?.data;
  }

  async setRoutingStatus(status: LivechatRoutingStatusEnum, email: string) {
    const { data: responseData } = await instance.post(
      `/api/agent/set-routing-status`,
      {
        email,
        status,
      }
    );
    return responseData?.data;
  }

  async readChat(chatId: string, threadId?: string): Promise<LivechatChatData> {
    const response = await instance.post(`/api/chat/action/read`, {
      chatId,
      threadId,
    });

    const responseData = response?.data || {};

    return responseData?.data;
  }

  async readHistory(chatId: string, pageId: string, minEventsCount = 20) {
    let allEvents: LivechatChatEventData[] = [];
    let nextPageId = pageId;

    const fetchHistory = async (
      chatId: string,
      pageId: string,
      minEventsCount: number
    ) => {
      type Params = {
        chatId: string;
        minEventsCount?: number;
        pageId?: string;
      };

      const params: Params = {
        chatId,
      };

      if (!pageId) {
        params.minEventsCount = minEventsCount;
        params.pageId = '';
      } else {
        params.pageId = pageId;
      }

      const response = await instance.post(
        '/api/chat/action/read-history',
        params
      );
      return response;
    };

    do {
      const { data: responseData } = await fetchHistory(
        chatId,
        nextPageId,
        minEventsCount
      );

      const filteredEvents: LivechatChatEventData[] = responseData.data
        .flatMap((thread: LivechatChatThreadData) => {
          return thread.events.map((event) => {
            if (event) {
              return { ...event, threadId: thread.id };
            }
          });
        })
        .filter((event: LivechatChatEventData) => {
          if (!event) return false;

          if (event.type === LivechatEventTypeEnum.SystemMessage) {
            const systemMessageEvent =
              event as LivechatChatSystemMessageEventData;

            return !['routing.idle', 'routing.archived_inactive'].includes(
              systemMessageEvent.systemMessageType
            );
          }

          return true;
        });

      allEvents = [...allEvents, ...filteredEvents];
      nextPageId = responseData.meta.nextPageId;
    } while (nextPageId && allEvents.length < minEventsCount);

    return {
      events: allEvents,
      nextPageId,
    };
  }

  async uploadFile(
    formData: FormData,
    options: AxiosRequestConfig
  ): Promise<string> {
    const response = await instance.post(`/api/file/upload`, formData, options);
    const responseData = response?.data || {};

    return responseData?.data;
  }

  async customerSatisfactionScore(
    distribution: string,
    fromDate: string,
    toDate: string
  ) {
    const { data } = await instance.get(
      `/api/insights/customer-satisfaction-score`,
      {
        params: {
          distribution,
          fromDate,
          toDate,
        },
      }
    );
    return data;
  }

  async createChannel(type: string, members: string[], name?: string) {
    const params: {
      type: string;
      directChannelData?: {
        member: string;
      };
      publicChannelData?: {
        members: string[];
        name?: string;
      };
    } = {
      type,
    };

    if (type === TextChatChannelTypeEnum.Public) {
      params['publicChannelData'] = {
        members,
        name,
      };
    } else if (type === TextChatChannelTypeEnum.Direct) {
      params['directChannelData'] = {
        member: members[0],
      };
    }

    const response = await instance.post(`/api/internal-chat/channel`, params);

    const responseData = response?.data || {};

    return responseData?.data;
  }

  async updateChannel(
    channelId: string,
    name?: string,
    userIdsToAdd?: string[],
    userIdsToRemove?: string[]
  ) {
    const params: {
      channelId: string;
      newName?: string;
      userIdsToAdd?: string[];
      userIdsToRemove?: string[];
    } = {
      channelId,
    };

    if (name) {
      params['newName'] = name;
    }

    if (userIdsToAdd && userIdsToAdd.length > 0) {
      params['userIdsToAdd'] = userIdsToAdd;
    }

    if (userIdsToRemove && userIdsToRemove.length > 0) {
      params['userIdsToRemove'] = userIdsToRemove;
    }

    const response = await instance.post(
      `/api/internal-chat/channel/update`,
      params
    );

    const responseData = response?.data || {};

    return responseData?.data;
  }

  async getChannels() {
    const response = await instance.get(`/api/internal-chat/channel`);
    const responseData = response?.data || {};

    return responseData?.data || [];
  }

  async getChannelHistory(channelId: string, pageId?: string) {
    const response = await instance.post(`/api/internal-chat/channel/history`, {
      channelId,
      pageId,
    });
    const responseData = response?.data || {};

    return (
      responseData || {
        data: [],
        meta: {
          nextPageId: null,
        },
      }
    );
  }

  async getOrganizationImport() {
    const response = await instance.get(`/api/organization-import`);
    const responseData = response?.data || {};

    return responseData?.data || [];
  }
}

export default TeamChat;
