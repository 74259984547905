import axios from 'axios';

import { config } from '@teamchat-shared/lib/config';

const instance = axios.create({
  baseURL: config.textApiUrl,
});

class Text {
  constructor(token?: string) {
    if (token) {
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  async getMessages(channelId: string, pageId?: string) {
    const { data } = await instance.post(`/chat/action/get_messages`, {
      channel_id: channelId,
      page_id: pageId,
    });

    return data;
  }

  async createChannel(name: string, type = 'public', members: string[]) {
    const { data } = await instance.post(`/chat/action/create_channel`, {
      name,
      type,
      members,
    });

    return data;
  }

  async sendMessage(channel: string, text: string) {
    const { data } = await instance.post(`/chat/action/send_message`, {
      channel,
      message: {
        type: 'text',
        text,
      },
    });

    return data;
  }
}

export default Text;
