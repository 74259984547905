import axios from 'axios';

export function getStatusCode(error: any): number | undefined {
  if (axios.isAxiosError(error)) {
    return error.response?.status;
  }

  return undefined;
}

export function getErrorCode(error: any): string | undefined {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.errorCode;
  }

  return undefined;
}

export function getErrorMessage(error: any) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    // Check for nested error message
    if (
      error.response.data.error.error &&
      error.response.data.error.error.message
    ) {
      return error.response.data.error.error.message;
    }
    // Fallback for a different error message structure
    else if (typeof error.response.data.error === 'string') {
      return error.response.data.error;
    }
  }

  // Fallback for an unstructured error
  return error.message || 'Unknown error occurred';
}
