import axios from 'axios';

import { config } from '@teamchat-shared/lib/config';

const instance = axios.create({
  baseURL: config.accountsURL,
});

class Accounts {
  constructor(token?: string) {
    if (token) {
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  async deleteSessions() {
    const { data } = await instance.delete(`/v2/sessions`);

    return data;
  }

  async getMyProducts() {
    const { data } = await instance.get(`/v2/organizations/my/products`);

    return data;
  }
}

export default Accounts;
